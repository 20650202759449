<template>
  <div class="container-fluid p-0">
    <div class="row justify-content-between">
      <div class="col-2">
        <span class="text-white bg-primary rounded-pill py-1 px-3 d-table mb-3"
          >Clients Sumary</span
        >
      </div>
      <div class="col-6">
        <el-button
          type="primary"
          class="float-right"
          @click="details = !details"
          >Toggle Details</el-button
        >
        <el-button
          type="primary"
          class="float-right mr-2"
          @click="fillData(!activeClients)"
          >{{ activeClients ? "Inactive" : "Active" }}</el-button
        >
      </div>
      <div class="col-4">
        <multiselect
          v-model="yearsClientSumary"
          @input="selectClientSumary"
          :options="optionYears"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Years"
          label="year"
          track-by="year"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.year;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
    </div>
    <div v-show="details">
      <div
        class="bg-white shadow-sm rounded-lg p-4 mt-2"
        v-for="stats in clientsSumary"
        :key="stats.year"
      >
        <el-table :data="stats.data" class="w-100 table-bordered">
          <el-table-column
            prop="clientInfo"
            label="Clients"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="month1"
            :label="'Jan-' + stats.year"
          ></el-table-column>
          <el-table-column
            prop="month2"
            :label="'Feb-' + stats.year"
          ></el-table-column>
          <el-table-column
            prop="month3"
            :label="'Mar-' + stats.year"
          ></el-table-column>
          <el-table-column
            prop="month4"
            :label="'Apr-' + stats.year"
          ></el-table-column>
          <el-table-column
            prop="month5"
            :label="'May-' + stats.year"
          ></el-table-column>
          <el-table-column
            prop="month6"
            :label="'Jun-' + stats.year"
          ></el-table-column>
          <el-table-column
            prop="month7"
            :label="'Jul-' + stats.year"
          ></el-table-column>
          <el-table-column
            prop="month8"
            :label="'Aug-' + stats.year"
          ></el-table-column>
          <el-table-column
            prop="month9"
            :label="'Sep-' + stats.year"
          ></el-table-column>
          <el-table-column
            prop="month10"
            :label="'Oct-' + stats.year"
          ></el-table-column>
          <el-table-column
            prop="month11"
            :label="'Nov-' + stats.year"
          ></el-table-column>
          <el-table-column
            prop="month12"
            :label="'Dec-' + stats.year"
          ></el-table-column>
          <el-table-column prop="total" label="Total"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="bg-white shadow-sm rounded-lg p-4 mt-2">
      <div class="container">
        <div class="row justify-content-center">
          <h3>{{ activeClients ? "Active Clients" : "Inactive Clients" }}</h3>
        </div>
      </div>

      <el-table :data="chartTableData" class="w-100 table-bordered">
        <el-table-column prop="year" label="Year"></el-table-column>
        <el-table-column prop="month1" label="Jan"></el-table-column>
        <el-table-column prop="month2" label="Feb"></el-table-column>
        <el-table-column prop="month3" label="Mar"></el-table-column>
        <el-table-column prop="month4" label="Apr"></el-table-column>
        <el-table-column prop="month5" label="May"></el-table-column>
        <el-table-column prop="month6" label="Jun"></el-table-column>
        <el-table-column prop="month7" label="Jul"></el-table-column>
        <el-table-column prop="month8" label="Aug"></el-table-column>
        <el-table-column prop="month9" label="Sep"></el-table-column>
        <el-table-column prop="month10" label="Oct"></el-table-column>
        <el-table-column prop="month11" label="Nov"></el-table-column>
        <el-table-column prop="month12" label="Dec"></el-table-column>
      </el-table>
    </div>
    <div class="chart-container">
      <clients-chart
        v-if="chartLoaded"
        :chart-data="datacollection"
        :options="this.chartOptions"
      ></clients-chart>
    </div>
  </div>
</template>

<script>
import corporate from "@/services/api/corporate";
import ClientsChart from "./clientsLine.js";

import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
    ClientsChart,
  },
  data() {
    return {
      details: false,
      activeClients: true,
      chartData: null,
      chartTableData: [],
      clientsSumary: [],
      yearsClientSumary: [{ year: new Date().getFullYear() }],
      optionYears: this.generateArrayOfYears(),
      baseClientInfo: ["Previous", "(+) New", "(-) Out", "(=) Active Clients"],
      datacollection: null,
      chartLoaded: false,
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                  // when the floored value is the same as the value we have a whole number
                  if (Math.floor(label) === label) {
                    return label;
                  }
                },
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0,
          },
        },
      },
    };
  },
  mounted() {
    this.loadClientSumary([new Date().getFullYear()]);
  },
  methods: {
    selectClientSumary() {
      let years = this.yearsClientSumary.map((year) => {
        return year.year;
      });

      if (years.length > 0) {
        this.loadClientSumary(years);
      } else {
        this.yearsClientSumary = [{ year: new Date().getFullYear() }];
        this.loadClientSumary([new Date().getFullYear()]);
      }
    },
    loadClientSumary(years) {
      const data = {
        years: years,
      };

      this.clientsSumary = [];

      corporate.getStatsSumary(data).then((response) => {
        response.forEach((stats) => {
          var data = Array();
          var responseData = Array();

          responseData["clientInfo"] = this.baseClientInfo[0];
          responseData = Object.assign({}, responseData, stats.data.previous);
          data.push(Object.assign({}, responseData));

          responseData["clientInfo"] = this.baseClientInfo[1];
          responseData = Object.assign({}, responseData, stats.data.new);
          data.push(Object.assign({}, responseData));

          responseData["clientInfo"] = this.baseClientInfo[2];
          responseData = Object.assign({}, responseData, stats.data.out);
          data.push(Object.assign({}, responseData));

          responseData["clientInfo"] = this.baseClientInfo[3];
          responseData = Object.assign({}, responseData, stats.data.active);
          data.push(Object.assign({}, responseData));

          this.clientsSumary.unshift({ year: stats.year, data: data });
        });
      });

      corporate.getChartClientSumary(data).then((response) => {
        this.chartData = response;
        this.fillData(this.activeClients);
      });
    },
    fillData(activeClients) {
      this.activeClients = activeClients;
      let chartTableDataArr = [];
      let datasets = this.chartData.map(function(chartData) {
        var dynamicColors = function() {
          var r = Math.floor(Math.random() * 255);
          var g = Math.floor(Math.random() * 255);
          var b = Math.floor(Math.random() * 255);
          return "rgb(" + r + "," + g + "," + b;
        };

        let dataSumary = activeClients
          ? chartData.data.active
          : chartData.data.inactive;

        chartTableDataArr.push(dataSumary);

        let dataSumaryArr = [
          dataSumary["month1"],
          dataSumary["month2"],
          dataSumary["month3"],
          dataSumary["month4"],
          dataSumary["month5"],
          dataSumary["month6"],
          dataSumary["month7"],
          dataSumary["month8"],
          dataSumary["month9"],
          dataSumary["month10"],
          dataSumary["month11"],
          dataSumary["month12"],
        ];
        const color = dynamicColors();
        return {
          label: chartData.year,
          backgroundColor: color + ",0.2)",
          borderColor: color + ")",
          pointBackgroundColor: color + ")",
          borderWidth: 1,
          pointBorderColor: color + ")",
          data: dataSumaryArr,
        };
      });

      this.chartTableData = chartTableDataArr;

      this.datacollection = {
        labels: this.labels,
        datasets: datasets,
      };
      this.chartLoaded = true;
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear();
      var min = max - 9;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push({ year: i });
      }
      return years;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.chart-container {
  width: 100% !important;
  height: 500px !important;
  margin: auto;
  margin-top: 0.5rem !important;
}

#line-chart {
  padding: 1rem !important;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-radius: 0.75rem !important;
}

.chartjs-render-monitor {
  height: 500px !important;
  z-index: 5;
}
</style>
